<template>
  <v-container fluid class="grey lighten-3">

    <v-card>
      <v-card-title>
        {{ name }}
      </v-card-title>

      <v-card-text>
        <v-container fluid>

          <v-form ref="form">
            <v-row>
              <v-col md="6">
                <h2 class="mb-8">Permissions</h2>
                <span v-for="perm in availablePermissions" :key="perm.id">
                  <v-checkbox v-model="userPermissionIDs" dense :label="perm.name" :value="perm.id"></v-checkbox>
                </span>
              </v-col>
              <v-col md="6">
                <h2 class="mb-8">Actions</h2>
                <v-btn color="error" :loading="deactivating" @click="deactivate()">Deactivate</v-btn>
                <v-fade-transition mode="out-in">
                  <v-btn color="green darken-1" text v-show="showDeactivated">Done</v-btn>
                </v-fade-transition>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions class="pl-5 pb-5">
        <v-btn class="mr-2" :to="{ name: 'Users' }">Back</v-btn>
        <v-btn color="primary" :loading="saving" @click="save()">Save</v-btn>

        <v-fade-transition mode="out-in">
          <v-btn color="green darken-1" text v-show="showSaved">Saved</v-btn>
        </v-fade-transition>

      </v-card-actions>

    </v-card>

  </v-container>
</template>

<script>
import formsMixin from '@/mixins/forms'

export default {
  name: 'UserDetail',

  props: {
    id: { type: Number, required: true },
  },

  data () {
    return {
      item: {
        first_name: null,
        last_name: null,
        permissions_csv: null,
      },
      valid: false,
      userPermissionIDs: [],
      loading: false,
      deactivating: false,
      showDeactivated: false,
      saving: false,
      showSaved: false,
    }
  },

  computed: {
    availablePermissions () {
      return this.$store.state.system.permissions
    },

    deactivateEndpoint () {
      return '/a/system/users/' + this.id + '/deactivate'
    },

    fetchUserEndpoint () {
      return '/a/system/users/' + this.id
    },

    name () {
      return this.item.first_name + ' ' + this.item.last_name
    },

    syncPermissionsEndpoint () {
      return '/a/system/users/' + this.id + '/sync-permissions'
    },
  },

  components: {
  },

  mixins: [formsMixin],

  watch: {
    availablePermissions: {
      handler () {
        this.fetchUser()
      }
    },
  },

  methods: {
    deactivate: function () {
      if (!confirm('Are you sure?')) {
        return
      }
      this.deactivating = true

      this.$http.put(this.deactivateEndpoint, null).then(resp => {
        this.showDeactivated = true
        this.timer = setTimeout(() => { this.showDeactivated = false }, 3000)

        // for now, return to table since detail page should not be shown for a deactivated user
        this.$router.push('/users')
      })
      .catch(errResp => {
        this.$store.commit('system/setAPIError', { method: 'PUT', url: this.deactivateEndpoint, description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
      })
      .finally(() => this.deactivating = false)
    },

    fetchUser: function () {
      this.loading = true

      var myURL = this.fetchUserEndpoint + '?xfields='
      // only get the fields needed to fill the item object
      myURL = this.forms_addItemFieldsToURI(myURL, this.item, [])

      this.$http.get(myURL).then(resp => {
        this.item = resp.data.data
        this.userPermissionIDs = []
        if (this.item.permissions_csv) {
          var userPermissionsNames = this.item.permissions_csv.split(',')
          this.availablePermissions.forEach(ele => {
            if (userPermissionsNames.includes(ele.name)) {
              this.userPermissionIDs.push(ele.id)
            }
          })
        }
      })
      .catch(errResp => {
        this.$store.commit('system/setAPIError', { method: 'GET', url: myURL, description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
      })
      .finally(() => this.loading = false)
    },

    save: function () {
      // make sure all mandatory fields have a value
      if (!this.$refs.form.validate()) {
        return
      }
      this.saving = true

      // only send the required item fields to API
      let saveObj = {
        'new_permission_ids': this.userPermissionIDs,
      }
      var body = JSON.stringify(saveObj)
      this.$http.put(this.syncPermissionsEndpoint, body).then(resp => {
        this.showSaved = true
        this.timer = setTimeout(() => { this.showSaved = false }, 3000)
        this.fetchUser()
      })
      .catch(errResp => {
        this.$store.commit('system/setAPIError', { method: 'PUT', url: this.syncPermissionsEndpoint, description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
      })
      .finally(() => this.saving = false)
    },
  },

  created: function () {
    this.fetchUser()
  },
}
</script>
